import {
  Component,
  HostBinding,
  OnDestroy,
  Input,
  OnInit
} from '@angular/core';

import { CarouselComponent } from './carousel.component';


export class SlideComponent implements OnInit, OnDestroy {
  /** Is current slide active */
  
  
  active: boolean;

  /** Wraps element by appropriate CSS classes */
  
  
  addClass = true;

  /** Link to Parent(container-collection) component */
  protected carousel: CarouselComponent;

  constructor(carousel: CarouselComponent) {
    this.carousel = carousel;
  }

  /** Fires changes in container collection after adding a new slide instance */
  ngOnInit(): void {
    this.carousel.addSlide(this);
  }

  /** Fires changes in container collection after removing of this slide instance */
  ngOnDestroy(): void {
    this.carousel.removeSlide(this);
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'slide',
  template: `
    <div [class.active]="active" class="item">
      <ng-content></ng-content>
    </div>
  `,
  host: {
    '[attr.aria-hidden]': '!active'
  }
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: CarouselComponent, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'active': [{ type: HostBinding, args: ['class.active', ] },{ type: Input },],
'addClass': [{ type: HostBinding, args: ['class.item', ] },{ type: HostBinding, args: ['class.carousel-item', ] },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
