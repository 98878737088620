import { Injectable } from '@angular/core';

/** Default dropdown configuration */

export class BsDropdownConfig {
  /** default dropdown auto closing behavior */
  autoClose = true;
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
