import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RatingComponent } from './rating.component';


export class RatingModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RatingModule,
      providers: []
    };
  }
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [CommonModule],
  declarations: [RatingComponent],
  exports: [RatingComponent]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
