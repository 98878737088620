import { NgModule, ModuleWithProviders } from '@angular/core';

import { CollapseDirective } from './collapse.directive';


export class CollapseModule {
  static forRoot(): ModuleWithProviders {
    return { ngModule: CollapseModule, providers: [] };
  }
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  declarations: [CollapseDirective],
  exports: [CollapseDirective]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
