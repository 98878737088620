// todo: add animations when https://github.com/angular/angular/issues/9947 solved
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2
} from '@angular/core';


export class CollapseDirective {
  /** This event fires as soon as content collapses */
   collapsed: EventEmitter<any> = new EventEmitter();
  /** This event fires as soon as content becomes visible */
   expanded: EventEmitter<any> = new EventEmitter();

   display: string;
  // shown
  
  
  
  isExpanded = true;
  // hidden
   isCollapsed = false;
  // stale state
   isCollapse = true;
  // animation state
   isCollapsing = false;

  /** A flag indicating visibility of content (shown or hidden) */
  
  set collapse(value: boolean) {
    this.isExpanded = value;
    this.toggle();
  }

  get collapse(): boolean {
    return this.isExpanded;
  }

  constructor(private _el: ElementRef, private _renderer: Renderer2) {
  }

  /** allows to manually toggle content visibility */
  toggle(): void {
    if (this.isExpanded) {
      this.hide();
    } else {
      this.show();
    }
  }

  /** allows to manually hide content */
  hide(): void {
    this.isCollapse = false;
    this.isCollapsing = true;

    this.isExpanded = false;
    this.isCollapsed = true;

    this.isCollapse = true;
    this.isCollapsing = false;

    this.display = 'none';
    this.collapsed.emit(this);
  }

  /** allows to manually show collapsed content */
  show(): void {
    this.isCollapse = false;
    this.isCollapsing = true;

    this.isExpanded = true;
    this.isCollapsed = false;

    this.display = 'block';
    // this.height = 'auto';
    this.isCollapse = true;
    this.isCollapsing = false;
    this._renderer.setStyle(
      this._el.nativeElement,
      'overflow',
      'visible'
    );
    this._renderer.setStyle(this._el.nativeElement, 'height', 'auto');
    this.expanded.emit(this);
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[collapse]',
  exportAs: 'bs-collapse',
  host: {
    '[class.collapse]': 'true'
  }
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'collapsed': [{ type: Output },],
'expanded': [{ type: Output },],
'display': [{ type: HostBinding, args: ['style.display', ] },],
'isExpanded': [{ type: HostBinding, args: ['class.in', ] },{ type: HostBinding, args: ['class.show', ] },{ type: HostBinding, args: ['attr.aria-expanded', ] },],
'isCollapsed': [{ type: HostBinding, args: ['attr.aria-hidden', ] },],
'isCollapse': [{ type: HostBinding, args: ['class.collapse', ] },],
'isCollapsing': [{ type: HostBinding, args: ['class.collapsing', ] },],
'collapse': [{ type: Input },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
