import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { CarouselComponent } from './carousel.component';
import { SlideComponent } from './slide.component';
import { CarouselConfig } from './carousel.config';


export class CarouselModule {
  static forRoot(): ModuleWithProviders {
    return { ngModule: CarouselModule, providers: [] };
  }
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [CommonModule],
  declarations: [SlideComponent, CarouselComponent],
  exports: [SlideComponent, CarouselComponent],
  providers: [CarouselConfig]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
