import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { BsDropdownState } from './bs-dropdown.state';


export class BsDropdownToggleDirective implements OnDestroy {
   isDisabled: boolean = null;

  // @HostBinding('class.active')
   isOpen: boolean;

  private _subscriptions: Subscription[] = [];

  constructor(private _state: BsDropdownState, private _element: ElementRef) {
    // sync is open value with state
    this._subscriptions.push(
      this._state.isOpenChange.subscribe(
        (value: boolean) => (this.isOpen = value)
      )
    );
    // populate disabled state
    this._subscriptions.push(
      this._state.isDisabledChange.subscribe(
        (value: boolean) => (this.isDisabled = value || null)
      )
    );
  }

  
  onClick(): void {
    if (this.isDisabled) {
      return;
    }
    this._state.toggleClick.emit(true);
  }

  
  onDocumentClick(event: any): void {
    if (
      this._state.autoClose &&
      event.button !== 2 &&
      !this._element.nativeElement.contains(event.target)
    ) {
      this._state.toggleClick.emit(false);
    }
  }

  
  onEsc(): void {
    if (this._state.autoClose) {
      this._state.toggleClick.emit(false);
    }
  }

  ngOnDestroy(): void {
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[bsDropdownToggle],[dropdownToggle]',
  exportAs: 'bs-dropdown-toggle',
  host: {
    '[attr.aria-haspopup]': 'true'
  }
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: BsDropdownState, },
{type: ElementRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'isDisabled': [{ type: HostBinding, args: ['attr.disabled', ] },],
'isOpen': [{ type: HostBinding, args: ['attr.aria-expanded', ] },],
'onClick': [{ type: HostListener, args: ['click', [], ] },],
'onDocumentClick': [{ type: HostListener, args: ['document:click', ['$event'], ] },],
'onEsc': [{ type: HostListener, args: ['keyup.esc', ] },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
