import { Injectable } from '@angular/core';


export class BsModalRef {
  /**
   * Reference to a component inside the modal. Null if modal's been created with TemplateRef
   */
  content?: any | null;

  /**
   * Hides the modal
   */
  hide: () => void = Function;
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
