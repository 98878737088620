import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { BsDropdownState } from './bs-dropdown.state';


export class BsDropdownMenuDirective {
  constructor(
    _state: BsDropdownState,
    _viewContainer: ViewContainerRef,
    _templateRef: TemplateRef<any>
  ) {
    _state.resolveDropdownMenu({
      templateRef: _templateRef,
      viewContainer: _viewContainer
    });
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[bsDropdownMenu],[dropdownMenu]',
  exportAs: 'bs-dropdown-menu'
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: BsDropdownState, },
{type: ViewContainerRef, },
{type: TemplateRef, },
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
